import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import Layout from '../../layouts'

const PageModellen = ({ data }) => {
  const page = data.page
  return (
    <Layout>
      <Helmet>
        <title>{page.title} - Sureventure Camperbouw</title>
        <meta name="description" content={page.metaDescription} />
      </Helmet>
      <section className="panel content-text">
        {page.title &&
          <h1>{page.title}</h1>
        }
        {page.intro &&
          <div className="intro">{page.intro}</div>
        }
        {page._rawBody &&
          <BlockContent blocks={page._rawBody} />
        }
      </section>
      <section id="lijst" className="panel panel--xlarge portfolio-list">
        {page.modellen && page.modellen.map(function(model, index){
          return (
            <article key={index} className="portfolio-list__item">
                <div className={index%2 === 0 ? 'row order-even block v-align-top' : 'row order-odd block v-align-top'}>
                    <div className="small-12 medium-6 large-8 columns content-img">
                      <Link to={`/modellen/${model.slug.current}`}>
                        {model.listImage &&
                          <Img fluid={model.listImage.asset.fluid} alt={model.title}/>
                        }
                      </Link>
                    </div>
                    <div className="small-12 medium-6 large-4 columns content-text">
                      <h2 className="portfolio-list__item__title heading-beta"><Link to={`/modellen/${model.slug.current}`}>{model.title}</Link></h2>
                      {model.descriptionShort &&
                        <div className="portfolio-list__item__description">{model.descriptionShort}</div>
                      }
                      <Link to={`/modellen/${model.slug.current}`}>Bekijk dit model</Link>
                    </div>
                </div>
            </article>
          );
        })}
      </section>
    </Layout>
  )
}

export default PageModellen

export const query = graphql`
  query {
    page: sanityModellen {
      title
      metaDescription
      intro
      _rawBody
      modellen {
        id
        title
        slug {
          current
        }
        type
        descriptionShort
        listImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`

